import { graphql } from 'gatsby';
import React from 'react';
import Hellheim from '../src/components/Hellheim/Hellheim';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import './closed.scss';

export const GatsbyQuery = () => graphql`
  query closed($locale: String!) {
    allClosedMainText(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          text
        }
      }
    }
    allClosedFaq(
      filter: { lang: { eq: $locale }, faq_type: { eq: "club" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          answer
          question
          faq_type
        }
      }
    }
    hotelesfaq: allClosedFaq(
      filter: { lang: { eq: $locale }, faq_type: { eq: "hoteles" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          answer
          question
          faq_type
        }
      }
    }
    allClosedSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    parquesfaq: allClosedFaq(
      filter: { lang: { eq: $locale }, faq_type: { eq: "parques" } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          answer
          question
          faq_type
        }
      }
    }
    allClosedFaQtitle(
      filter: { lang: { eq: $locale }, title: { eq: "Preguntas sobre nuestros parques" } }
    ) {
      nodes {
        tag
        title
        lang
      }
    }
    allClosedFaQimage(filter: { lang: { eq: $locale } }) {
      nodes {
        image
        alt
      }
    }
  }
`;

class Closed extends React.Component {
  state = {};

  render() {
    const hellheimDataPark = {
      title: this.props.data.allClosedFaQtitle.nodes[0].tag,
      text: '',
      menus: this.props.data.parquesfaq.edges.map((item) => ({
        title: item.node.question,
        text: item.node.answer,
      })),
      titleType: 'h3',
      icon: 'park',
      id: 'park',
    };
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allClosedSeoData.edges[0].node._0.title}
          description={this.props.data.allClosedSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
          disabled
        />
        <div className="is-fullWidth closed">
          <img className="image-desktop" src={this.props.data.allClosedFaQimage.nodes[0].image} />
          <img className="image-mobile" src={this.props.data.allClosedFaQimage.nodes[1].image} />
          <div
            className="closed-text"
            dangerouslySetInnerHTML={{
              __html: this.props.data.allClosedMainText.edges[0].node.text,
            }}
          />
          <Hellheim data={hellheimDataPark} />
        </div>
      </Layout>
    );
  }
}

export default Closed;
